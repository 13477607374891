<template>
  <a-spin :spinning="spinning">
    <div class="warpper">
      <a-card>
        <a-row class="article">
          <a-col :span="20">
            <div class="year" v-bind="year">
              MHIAS-{{ JSON.stringify(this.year).slice(1, 5) }}年度各大区事务所销售及商流月实绩(单位：元)
            </div>
          </a-col>
          <!-- <a-col class="article" :span="4">
          <a-form-model-item class="search-area" label="大区事务所">
            <a-cascader
              style="width:200px"
              :options="dealerList"
              v-model="regionalOffice"
              change-on-select
              placeholder="请选择大区/事务所"
              @change="onChange"
              :field-names="{ label: 'title', value: 'id', children: 'children' }"
            />
          </a-form-model-item>
        </a-col> -->
          <a-col :span="2">
            <div class="year">
              <a-date-picker
                format="YYYY"
                mode="year"
                :value="year"
                :open="open"
                @openChange="openChange"
                @panelChange="panelChange"
              />
            </div>
          </a-col>
          <!-- 大区事务所销售及商流导出报表 -->
          <a-col style="margin: 12px 0 0 2%" :span="4">
            <downLoad
              method="post"
              api="/api/base/system/report/excelReportAreaAndFirmSales"
              :params="searchDataObj"
              @downLoadDone="downLoadDone"
              @startDownLoad="startDownLoad"
              :isConfirmLoading="tableLoading"
              name="大区事务所销售及商流报表.xls"
              >导出</downLoad
            >
          </a-col>
        </a-row>
        <br />
        <div>
          <a-table
            :scroll="tableSize"
            :loading="tableLoading"
            :columns="table_config.columns"
            :row-class-name="rowClassName"
            :dataSource="table_config.tableData"
            :rowKey="(record,index)=>{return index}"
            :pagination="false"
            bordered
          >
          </a-table>
        </div>
      </a-card>
    </div>
  </a-spin>
</template>
<script>
import ReportTableData from './components/ReportTableData.vue'
import moment from 'moment'
import { formatSale, formatAppoint } from './components/colums.js'
export default {
  components: { ReportTableData, moment },
  data() {
    return {
      tableSize: { x: 1500, y: 600 },
      text: '',
      open: false,
      spinning: false,
      year: moment(),
      month: moment().month() + 1,
      searchData: {
        date: moment().format('YYYY-MM'),
      },
      formatLength: '',
      regionalOffice: [],
      dealerList: [],
      tableLoading: false,
      table_config: {
        columns: [
          {
            title: '大区',
            dataIndex: 'title',
            align: 'center',
            key: 1,
            width: 150,
            fixed: 'left',
            customRender: (value, row, index) => {
              const obj = {
                children: value,
                attrs: {},
              }
              if (index === this.formatLength) {
                obj.attrs.colSpan = 0
              }
              return obj
            },
          },
          {
            title: '事务所',
            dataIndex: 'februaryAim',
            align: 'center',
            key: 2,
            width: 150,
            fixed: 'left',
            customRender: (value, row, index) => {
              const obj = {
                children: value,
                attrs: {},
              }
              if (index === this.formatLength) {
                obj.attrs.colSpan = 2
              }
              return obj
            },
          },
          {
            title: '1月销售',
            dataIndex: 'janXS',
            align: 'center',
            key: 3,
            width: 120,
            type: 'function',
            customCell: this.fontText,
          },
          {
            title: '1月商流',
            dataIndex: 'janSL',
            align: 'center',
            key: 4,
            width: 120,
            customCell: this.fontText,
          },
          {
            title: '2月销售',
            dataIndex: 'febXS',
            align: 'center',
            key: 5,
            width: 120,
            customCell: this.fontText,
          },
          {
            title: '2月商流',
            dataIndex: 'febSL',
            align: 'center',
            key: 6,
            width: 120,
            customCell: this.fontText,
          },
          {
            title: '3月销售',
            dataIndex: 'marXS',
            align: 'center',
            key: 7,
            width: 120,
            customCell: this.fontText,
          },
          {
            title: '3月商流',
            dataIndex: 'marSL',
            align: 'center',
            key: 8,
            width: 120,
            customCell: this.fontText,
          },
          {
            title: '4月销售',
            dataIndex: 'aprXS',
            align: 'center',
            key: 9,
            width: 120,
            customCell: this.fontText,
          },
          {
            title: '4月商流',
            dataIndex: 'aprSL',
            align: 'center',
            key: 10,
            width: 120,
            customCell: this.fontText,
          },
          {
            title: '5月销售',
            dataIndex: 'mayXS',
            align: 'center',
            key: 11,
            width: 120,
            customCell: this.fontText,
          },
          {
            title: '5月商流',
            dataIndex: 'maySL',
            align: 'center',
            key: 12,
            width: 120,
            customCell: this.fontText,
          },
          {
            title: '6月销售',
            dataIndex: 'juneXS',
            align: 'center',
            key: 13,
            width: 120,
            customCell: this.fontText,
          },
          {
            title: '6月商流',
            dataIndex: 'juneSL',
            align: 'center',
            key: 14,
            width: 120,
            customCell: this.fontText,
          },
          {
            title: '7月销售',
            dataIndex: 'julyXS',
            align: 'center',
            key: 15,
            width: 120,
            customCell: this.fontText,
          },
          {
            title: '7月商流',
            dataIndex: 'julySL',
            align: 'center',
            key: 16,
            width: 120,
            customCell: this.fontText,
          },
          {
            title: '8月销售',
            dataIndex: 'augXS',
            align: 'center',
            key: 17,
            width: 120,
            customCell: this.fontText,
          },
          {
            title: '8月商流',
            dataIndex: 'augSL',
            align: 'center',
            key: 18,
            width: 120,
            customCell: this.fontText,
          },
          {
            title: '9月销售',
            dataIndex: 'septXS',
            align: 'center',
            key: 19,
            width: 120,
            customCell: this.fontText,
          },
          {
            title: '9月商流',
            dataIndex: 'septSL',
            align: 'center',
            key: 20,
            width: 120,
            customCell: this.fontText,
          },
          {
            title: '10月销售',
            dataIndex: 'octXS',
            align: 'center',
            key: 21,
            width: 120,
            customCell: this.fontText,
          },
          {
            title: '10月商流',
            dataIndex: 'octSL',
            align: 'center',
            key: 22,
            width: 120,
            customCell: this.fontText,
          },
          {
            title: '11月销售',
            dataIndex: 'novXS',
            align: 'center',
            key: 23,
            width: 120,
            customCell: this.fontText,
          },
          {
            title: '11月商流',
            dataIndex: 'novSL',
            align: 'center',
            key: 24,
            width: 120,
            customCell: this.fontText,
          },
          {
            title: '12月销售',
            dataIndex: 'deceXS',
            align: 'center',
            key: 25,
            width: 120,
            customCell: this.fontText,
          },
          {
            title: '12月商流',
            dataIndex: 'deceSL',
            align: 'center',
            key: 26,
            width: 120,
            customCell: this.fontText,
          },
          {
            title: '2022年销售合计',
            dataIndex: 'payAmountXS',
            align: 'center',
            key: 27,
            width: 120,
            customCell: this.fontText,
          },
          {
            title: '2022年商流合计',
            dataIndex: 'payAmountSL',
            align: 'center',
            key: 28,
            width: 120,
            customCell: this.fontText,
          },
        ],
        tableData: [],
      },
    }
  },
  created() {
    this.getChoiceData()
    this.axios.post('/api/firm/mhiac/mhiacFirm/getTree').then((res) => {
      // 获取大区事务所经销商数据
      this.dealerList = res.body
    })
  },
  computed: {
    searchDataObj() {
      const obj = {
        ...this.searchData,
      }
      return obj
    },
  },
  methods: {
    moment,
    downLoadDone() {
      this.spinning = false
    },
    startDownLoad() {
      this.spinning = true
    },
    address(scope, item) {
      return scope
    },
    // onChange(val) {
    //   this.searchData.areaId = val[0]
    //   this.searchData.firmId = val[1]
    // },
    openChange(status) {
      if (status) {
        this.open = true
      } else {
        this.open = false
      }
    },
    panelChange(date) {
      this.year = date
      this.open = false
      this.text = JSON.stringify(this.year).slice(1, 5)
      this.searchData.date = date.format('YYYY')
      this.getChoiceData()
    },
    fontText() {
      return {
        style: {
          'text-align': 'right',
        },
      }
    },
    getChoiceData() {
      this.tableLoading = true
      this.axios
        .post('/api/base/system/report/getReportAreaAndFirmSales', this.searchData)
        .then((res) => {
          const res_tableData = []
          res.body.forEach((element) => {
            res_tableData.push({
              title: element.areaName,
              februaryAim: element.firmName,
              //1 ~ 12
              janXS: element.janXS.toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,'),
              janSL: element.janSL.toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,'),
              febXS: element.febXS.toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,'),
              febSL: element.febSL.toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,'),
              marXS: element.marXS.toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,'),
              marSL: element.marSL.toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,'),
              aprXS: element.aprXS.toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,'),
              aprSL: element.aprSL.toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,'),
              mayXS: element.mayXS.toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,'),
              maySL: element.maySL.toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,'),
              juneXS: element.juneXS.toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,'),
              juneSL: element.juneSL.toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,'),
              julyXS: element.julyXS.toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,'),
              julySL: element.julySL.toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,'),
              augXS: element.augXS.toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,'),
              augSL: element.augSL.toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,'),
              septXS: element.septXS.toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,'),
              septSL: element.septSL.toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,'),
              octXS: element.octXS.toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,'),
              octSL: element.octSL.toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,'),
              novXS: element.novXS.toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,'),
              novSL: element.novSL.toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,'),
              deceXS: element.deceXS.toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,'),
              deceSL: element.deceSL.toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,'),
              payAmountXS: element.payAmountXS.toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,'),
              payAmountSL: element.payAmountSL.toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,'),
              key: element.index,
            })
          })
          this.table_config.tableData = res_tableData
          this.formatLength = formatSale(res.body)
          this.tableLoading = false
        })
        .catch(() => {
          this.tableLoading = false
        })
    },
    rowClassName(record) {
      const className = 'light-row'
      const classNum = 'light-row'
      const classUN = 'light-row'
      const classUNum = 'light-row'
      if (record.februaryAim === '小计') {
        return {
          className,
        }
      } else if (record.februaryAim === '总计') {
        return {
          classNum,
        }
      } else if (
        record.areaName === '合计' ||
        record.channel === '合计' ||
        record.dept === '合计' ||
        record.firmName === '总计'
      ) {
        return {
          classUN,
        }
      } else if (record.categoryName === '小计' || record.categoryName === '三菱产品小计') {
        return {
          classUNum,
        }
      }
    },
  },
}
</script>
<style lang="less" scoped>
.year {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
}
.cell-class {
  background-color: red;
}
.litigationInfoListredRow {
  color: red;
}
.article {
  display: flex;
  align-items: center;
}
.search-area {
  display: flex;
  align-items: center;
  width: 300px;
  margin-top: 25px;
}
::v-deep .ant-table-thead > tr > th {
  text-align: center;
}
::v-deep .ant-table-thead > tr > th.headerPink {
  background-color: #fce4d6;
}
::v-deep .ant-table-thead > tr > th.headerGreen {
  background-color: #e2efda;
}
::v-deep .ant-table-thead > tr > th.headerOrange {
  background-color: #ffe699;
}
::v-deep .ant-table-thead > tr > th.leftHeader {
  background-color: #fff2cc;
}
::v-deep .ant-table-thead > tr > th.LightBlue {
  background-color: #ddebf7;
}
::v-deep .ant-table-tbody > .classOneTitle {
  background-color: #b4c6e7;
}
::v-deep .ant-table-tbody > .className {
  background-color: #b4c6e7;
}
::v-deep .ant-table-tbody > .classNum {
  background-color: #ffc000;
}
::v-deep .ant-table-tbody > .classUN {
  background-color: #b4c6e7;
}
::v-deep .ant-table-tbody > .classUNum {
  background-color: #d9d9d9;
}
</style>