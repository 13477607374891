<template>
  <div class="warpper">
    <!-- 表格头 -->
    <a-table
      ref="tableElRef"
      :loading="tableLoading"
      :dataSource="dataSource"
      :rowKey="(record) => record.number"
      :row-class-name="rowClassName"
      :pagination="false"
      :scroll="scroll"
      bordered
    >
      <!-- 表格头 -->
      <template v-for="item in columns">
        <a-table-column
          v-if="item.type === 'function'"
          :key="item.dataIndex"
          :title="item.title"
          :width="item.width"
          :data-index="item.dataIndex"
        >
          <template slot-scope="scope">
            <!-- 判断 传递数据 -->
            <span v-html="item.callback && item.callback(scope, item)"></span>
          </template>
        </a-table-column>
        <a-table-column
          v-else
          :key="item.dataIndex"
          :title="item.title"
          :width="item.width"
          :data-index="item.dataIndex"
        >
          <template slot-scope="scope">
            <!-- 判断 传递数据 -->
            <span>{{ scope }}</span>
          </template>
        </a-table-column>
      </template>
    </a-table>
  </div>
</template>
<script>
import moment from 'moment'
export default {
  name: 'table年度各大区事务所销售及商流月实绩报表',
  props: {
    scroll: {
      type: Object,
      default: () => ({}),
    },
    // 全部数据
    isShow: {
      type: Boolean,
      default: true,
    },
    columns: {
      type: Array,
      default: () => [],
    },
    dataSource: {
      type: Array,
      default: () => [],
    },
    tableLoading: {
      type: Boolean,
      default: false,
    },
  },
  // 通过计算属性获取父级attrs，作为table的props
  computed: {
    getBindValues() {
      return { ...this.$attrs }
    },
  },
  mounted() {},
  data() {
    return {
      titleSectionInit: '1',
      year: moment().year(),
      month: moment().month() + 1,
    }
  },
  methods: {
    rowClassName(record) {
      const className = 'light-row'
      const classNum = 'light-row'
      const classUN = 'light-row'
      const classUNum = 'light-row'
      if (record.februaryAim === '小计') {
        return {
          className,
        }
      } else if (record.februaryAim === '总计') {
        return {
          classNum,
        }
      } else if (
        record.areaName === '合计' ||
        record.channel === '合计' ||
        record.dept === '合计' ||
        record.firmName === '合计' ||
        record.firmName === '总计'
      ) {
        return {
          classUN,
        }
      } else if (record.categoryName === '小计' || record.categoryName === '三菱产品小计') {
        return {
          classUNum,
        }
      }
    },
  },
}
</script>
<style lang="less" scoped>
.month {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.85);
}
.month_gmv {
  margin-top: 20px;
}
.warpper {
  width: 100%;
  height: 100%;
}
::v-deep .ant-table-tbody > .className {
  background-color: #b4c6e7;
}
::v-deep .ant-table-tbody > .classNum {
  background-color: #ffc000;
}
::v-deep .ant-table-tbody > .classUN {
  background-color: #b4c6e7;
}
::v-deep .ant-table-tbody > .classUNum {
  background-color: #d9d9d9;
}
</style>
    